<template>
	<div class="notice">
		<div class="mask" @click="prompt" v-show="showMask"></div>
		<AddHospitalBox @show3='show3($event)'></AddHospitalBox>
		<PharmacyBox v-if='showHospitalBox' @close="closeHospitalBox($event)"></PharmacyBox>
		<KeBox :getKeName="getKeName"></KeBox>
		<ThemeBox :getThemeName="getThemeName"></ThemeBox>
		<TargetBox :getTargetName="getTargetName"></TargetBox>
		<div class="noticeBox">
			<h3>会议通知</h3>
			<div class="box box1">
				<span class="title">目标产品</span>

				<el-select v-model="productName" multiple filterable @blur='changeProduct("productName","reqMsgRef")'
					placeholder="请选择" size="small" ref="reqMsgRef">
					<el-option v-for="item in productList" :key="item.id" :label="item.label" :value="item.name">
					</el-option>
				</el-select>


				<!-- <el-select v-model="productName.name" class="m-2" @change='changeProduct' placeholder="请选择"
					size="small">
					<el-option v-for="item in productList" :key="item.id" :label="item.label" :value="item.name" />
				</el-select> -->
			</div>
			<div class="box box2">
				<span class="title">会议通知日期</span>
				<el-date-picker v-model="time" type="date" :size="size" format="YYYY/MM/DD" value-format="YYYY-MM-DD"
					@change='storage(8)' placeholder="选择日期">
				</el-date-picker>
				<!--<el-date-pickerv-model="date"type="daterange"range-separator="→"start-placeholder="开始时间"
					end-placeholder="结束时间":size="size"format="YYYY/MM/DD"value-format="YYYY-MM-DD"
					@change='changeDate'/>-->
			</div>
			<div class="box box2">
				<span class="title">会议日期</span>
				<el-date-picker v-model="date" type="date" :size="size" format="YYYY/MM/DD" value-format="YYYY-MM-DD"
					@change='changeDate' placeholder="选择日期">
				</el-date-picker>
				<!--<el-date-pickerv-model="date"type="daterange"range-separator="→"start-placeholder="开始时间"
					end-placeholder="结束时间":size="size"format="YYYY/MM/DD"value-format="YYYY-MM-DD"
					@change='changeDate'/>-->
			</div>
			<div class="box box2">
				<span class="title">会议时间</span>
				<el-time-picker is-range v-model="timeSolt" size="small" :value-format="'HH:mm'" :format="'HH:mm'"
					range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围"
					@change='storage(9)'>
				</el-time-picker>
				<!--<el-date-pickerv-model="date"type="daterange"range-separator="→"start-placeholder="开始时间"
					end-placeholder="结束时间":size="size"format="YYYY/MM/DD"value-format="YYYY-MM-DD"
					@change='changeDate'/>-->
			</div>

			<!--<divclass="boxbox2">
				<spanclass="title">会议通知时间</span>
				<el-time-pickerv-model="time"size="small":value-format="'HH:mm'":format="'HH:mm'"
					placeholder="请选择"@change='storage(8)'></el-time-picker>
			</div>-->
			<div class="box box3" @click="chooseHospital">
				<span class="title">参会药店</span>
				<span class="ans" :class="{'border':hospital==''}">{{hospital?hospital:'请选择'}}</span>
			</div>
			<!-- <div class="box box4" @click="chooseKe">
				<span class="title">参会科室</span>
				<span class="ans"
					:class="{'border':department.length==0}">{{department.length>0?department.map(item=>item.name).toString():'请选择'}}</span>
			</div> -->
			<div class="box box4" v-if="meetType!=1">
				<span class="title">详细地址</span>
				<el-input type="textarea" disabled class="address_content" :rows="2" placeholder="请输入详细地址" v-model="address"
					@blur="storage(12)" @focus='determine2'>
				</el-input>
			</div>
			<div class="box box4">
				<span class="title">会议主题</span>
				<!-- <span class="ans" :class="{'border':theme.length==0}">{{theme.length>0?theme[0].name:'请选择'}}</span> -->
				<span class="ans" :class="{'border':theme == ''}">{{theme != ''?theme:'请选择'}}</span>
			</div>
			<div class="box box4" @click='chooseTarget'>
				<span class="title">会议目标</span>
				<span class="ans"
					:class="{'border':target.length==0}">{{target.length>0?target.map(item=>item.name).toString():'请选择'}}</span>
			</div>
			<div class="box box4">
				<span class="title">参会人数</span>
				<input type="text" v-model="peopleNum" @blur="storage(11)" placeholder="请填写">
			</div>
			<div class="box box4">
				<span class="title">主讲者</span>
				<input type="text" v-model="speaker" @focus="determine" @blur="storage(7)" placeholder="请填写">
			</div>
			<!-- <div class="box box4" v-if="meetType!=1">
				<span class="title">会议地点</span>
				<el-cascader @change="storage(10)" size="small" :options="pcTextArr" v-model="selectedOptions">
				</el-cascader>
			</div> -->
			<div class="tan" v-if='showMask2'>
				<img src="../../assets/close2.png" @click="closes" alt="">
				<div class="area">
					<span>所在省市区</span>
					<el-cascader size="small" :options="pcTextArr" v-model="selectedOptions">
					</el-cascader>
				</div>
				<div class="address">
					<span>详细地址</span>
					<input type="text" v-model="addressDetail" placeholder="请输入详细地址">
				</div>
				<button @click="submit">确定</button>
			</div>
		</div>

	</div>
</template>

<script>
	import Mask from "@/components/Mask.vue";
	import AddHospitalBox from "@/components/AddHospitalBox.vue";
	import PharmacyBox from "@/components/Clerk/PharmacyBox.vue";
	import KeBox from "@/components/KeBox.vue";
	import ThemeBox from "@/components/ThemeBox.vue";
	import TargetBox from "@/components/TargetBox.vue";
	//引入axios
	import axios from 'axios';
	import {
		ref
	} from 'vue'
	//引入端口配置文件
	import API_LIST from '../../../list.config.js';
	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import {
		provinceAndCityData,
		pcTextArr,
		regionData,
		pcaTextArr,
		codeToText,
	} from "element-china-area-data";
	export default {
		name: "NoticeKe",
		data() {
			return {
				showMask: false,
				showMask2:false,
				complete: false, //线上会议通知是否填写完整
				productName: [], //产品
				hospital: '', //医院名
				department: [], //科室列表
				date: '', //日期
				time: '', //通知时间
				timeSolt: '', //会议时间
				productList: '', //产品列表
				size: 'small',
				theme: '店员培训会', //会议主题
				target: [], //会议目标
				peopleNum: '', //会议人数
				speaker: '', //主讲者
				pcTextArr,
				selectedOptions: [],
				address: '', // 详细地址
				showHospitalBox: false, // 显示医院的列表
				arr:[], // 
			}
		},
		components: {
			PharmacyBox,
			AddHospitalBox,
			KeBox,
			ThemeBox,
			TargetBox
		},
		created() {
			//医院
			this.$eventBus.off('hospitalChoose')


			this.xyID = this.$route.query.xyID
			this.meetID = this.$route.query.meetID
			this.meetType = this.$route.query.meetType
			this.getListInfo();
			this.getWriteInfo();
		},
		watch: {
			complete(newData, oldData) {
				console.log(newData, oldData)
				if (newData) {
					this.showMask = true;
					this.$eventBus.emit('allow', {
						allowData: newData
					})
				}
			}

		},
		methods: {
			//提示
			prompt() {
				//ElMessage({
				//	message:'该区域禁止更改',
				//	type:'warning',
				//})
			},
			//获取产品列表
			getListInfo() {
				let obj = {
					u_id: localStorage.getItem("u_id"),
					agreement_id: this.xyID,
					p_type: 1
				}
				let a = qs.stringify(obj)

				axios.post(API_LIST.tg_product_list, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {

						if (_d.data.status == 200) {
							this.productList = _d.data.list
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			//获取已经输入的值
			getWriteInfo() {

				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.meetID,
				}
				let a = qs.stringify(obj)

				axios.post(API_LIST.sel_notice_data3, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {

						console.log(11111, _d.data.list)
						if (_d.data.status == 200) {
							//this.productList=_d.data.list

							if (_d.data.list.meet_notice == 1) {
								this.complete = this.showMask = true
							} else {
								this.complete = this.showMask = false
							}
							this.productName = _d.data.list.product
							
							this.date = _d.data.list.meet_time
							if (_d.data.list.start_time) {
								this.timeSolt = [_d.data.list.start_time, _d.data.list.end_time]
							} else {
								this.timeSolt = ''
							}
							this.time = _d.data.list.notice_time
							this.hospital = _d.data.list.company
							this.department = _d.data.list.departList
							// console.log('-------',_d.data.list.meet_theme)
							// if (_d.data.list.meet_theme == []) {
							// 	this.theme = _d.data.list.meet_theme
							// } else {
							// 	let arr = []
							// 	arr.push(_d.data.list.meet_theme)
							// 	this.theme = arr
							// }
							// this.theme = this.productName.toString()+'产品贴柜培训会'
							this.target = _d.data.list.targetList
							this.peopleNum = _d.data.list.targetList
							this.speaker = _d.data.list.teacher
							this.selectedOptions = [_d.data.list.province, _d.data.list.city]
							this.peopleNum = _d.data.list.meet_p_num
							this.address = _d.data.list.address

						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {
						(error);
					});
			},
			//选择产品
			changeProduct(flag, ref) {
				const that = this;
				this.$refs[ref].$refs.input.blur = () => {
					// 这里执行失焦的代码
					if (this[flag].length > 0) {
						that.arr = []
						for (let i = 0; i < that[flag].length; i++) {
							let obj = that.productList.findIndex((item) => {
								return item.name === that[flag][i]
							})
							that.arr.push(that.productList[obj].id)
						}
						console.log(that.arr)
						
						this.storage(1)
					} else {
						ElMessage({
							message: '请选择目标产品',
							type: 'warning',
						})
					}

				};


				// let ind = this.productList.findIndex(item => {
				// 	return item.name == _name
				// })
				// this.productName = {
				// 	id: this.productList[ind].product_id,
				// 	name: this.productList[ind].name,
				// }
				// this.storage(1)

			},
			//储存实时数据
			storage(_state) {
				console.log(this.complete)
				if (this.complete) {
					ElMessage({
						message: '该区域禁止更改',
						type: 'warning',
					})
					return
				}
				let obj = {
					u_id: localStorage.getItem("u_id"),
					//product_id:this.productName.id,
					department_meet_id: this.meetID
				}
				switch (_state) {
					case 1:
						obj.product_id = this.arr
						break
					case 2:
						obj.company = this.hospital
						obj.province = this.selectedOptions[0]
						obj.city = this.selectedOptions[1]
						obj.address = this.address
						break
					case 3:
						obj.depart_name = this.department
						break
					case 4:
						// obj.meet_theme = this.theme[0]
						break
					case 5:
						obj.target = this.target
						break
					case 6:
						obj.meet_time = this.date
						break
					case 7:
						obj.teacher = this.speaker
						break
					case 8:
						obj.notice_time = this.time
						break
					case 9:
						obj.times = this.timeSolt[0] + '-' + this.timeSolt[1]
						break
					case 10:
						obj.province = this.selectedOptions[0]
						obj.city = this.selectedOptions[1]
						obj.address = this.address
						break
					case 11:
						obj.meet_p_num = this.peopleNum
						break
					case 12:
						obj.address = this.address
						break
				}


				let a = qs.stringify(obj)

				axios.post(API_LIST.save_pc_product3, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {

						if (_d.data.status == 200) {
							if (_state == 1) {
								this.hospital = '' //医院名
								this.department = [] //科室列表
								this.date = '' //日期
								this.time = '' //通知时间
								this.timeSolt = '' //会议时间
								// this.theme = [] //会议主题
								// this.theme = this.productName.toString()+'产品贴柜培训会'
								this.target = [] //会议目标
								this.peopleNum = '' //会议人数
								this.speaker = '' //主讲者
								this.selectedOptions = []
								this.address = '' // 详细地址
							}
							if (_d.data.list.meet_notice == 1) {
								console.log(_d.data.list.meet_notice)
								this.complete = true
							} else {
								this.complete = false
							}

							//location.reload()
						} else {
							if (_state == 6) {
								this.date = '' //日期
							}
							if (_state == 8) {
								this.timeSolt = ''
							}
							if (_state == 7) {
								this.speaker = '' //主讲者
							}
							if (_state == 3) {
								this.department = [] //科室列表
							}
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})


						}
					})
					.catch(function(error) {

					});
			},
			//选择日期
			changeDate(_date) {

				this.storage(6)
			},
			//选择医院
			chooseHospital() {
				this.showHospitalBox = true;
				// this.$eventBus.emit('show3', {
				// 	show: true
				// })
			},
			//选择科室
			chooseKe() {
				if (!this.hospital) {
					ElMessage({
						message: '请选择医院',
						type: 'warning',
					})
					return
				}
				if (this.productName.length == 0) {
					ElMessage({
						message: '请选择目标产品',
						type: 'warning',
					})
					return
				}
				this.$eventBus.emit('showKeBox', {
					show: true,
					meetID: this.meetID
				})
			},
			//获取科室名称
			getKeName(name) {

				this.department = name
				this.storage(3)
			},
			//选择会议主题
			chooseTheme() {
				if (this.productName.length == 0) {
					ElMessage({
						message: '请选择目标产品',
						type: 'warning',
					})
					return
				}
				this.$eventBus.emit('showThemeBox', {
					show: true,
					meetID: this.meetID
				})
			},
			//获取主题名称
			getThemeName(name) {

				this.theme = name
				this.storage(4)
			},
			//会议目标
			chooseTarget() {
				if (this.productName.length == 0) {
					ElMessage({
						message: '请选择目标产品',
						type: 'warning',
					})
					return
				}
				this.$eventBus.emit('showTargetBox', {
					show: true,
					meetID: this.meetID
				})
			},
			//获取目标名称
			getTargetName(name) {

				this.target = name
				this.storage(5)
			},
			//输入主讲者时判断是否填写了会议日期
			determine(e) {
				if (!this.date) {
					ElMessage({
						message: '请先选择会议日期',
						type: 'warning',
					})
					e.srcElement.blur();
				}
			},
			// 输入详细地址时判定是否选择了会议地点
			determine2(e) {
				if (this.selectedOptions.length == 0) {
					ElMessage({
						message: '请先选择会议地点',
						type: 'warning',
					})
					e.srcElement.blur();
				}
			},
			// 关闭显示医院列表
			closeHospitalBox(e) {
				this.showHospitalBox = e.show
				if (e.state == 1) {
					this.hospital = e.hospitalName
					this.address = e.address.address
					this.selectedOptions = [e.address.province, e.address.city]
					this.storage(2)
				}
			},
			// 
			show3(e){
				console.log(e)
				this.showHospitalBox = e.show
			},
			//
			showTanBox(){
				console.log(12345)
				this.showMask2 = true;
				this.$emit('show',{})
			},
			submit(){
				if(this.selectedOptions[0] == '' || this.selectedOptions[1] == '' || this.addressDetail == ''){
					ElMessage({
						message: '请输入完整地址',
						type: 'warning',
					})
					return
				}
				this.province = this.selectedOptions[0]
				this.city = this.selectedOptions[1]
				this.address = this.province + this.city + this.addressDetail
				this.showMask2 = false;
				this.closes()
				this.storage(10)
			},
			closes(){
				this.showMask2 = false;
				this.$emit('closes',{})
			}
		}
	}
</script>

<style scoped lang="scss">
	.notice {
		background-color: #fff;
		padding: 15px 0;
		position: relative;
		box-shadow: 0px 0px 20px #ccc;
		margin: 0 auto;
		width: 80%;
		border-radius: 10px;
		// border: 1px solid #1890FF;

		.mask {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			z-index: 100;
			background-color: #fff;
			opacity: 0.3;

		}
		.tan{
			position:fixed;
			top:0;
			bottom:0;
			left:0;
			right:0;
			margin:auto;
			width:528px;
			height:363px;
			display:flex;
			flex-direction:column;
			align-items:center;
			background-color:#fff;
			z-index:101;
			border-radius:10px;
		
			img{
				position:absolute;
				right:5px;
				top:5px;
				width:34px;
				height:34px;
				align-items:flex-end;
			}
		
			.title{
				padding:20px 0;
				font-size:14px;
				font-weight:800;
			}
		
			.name{
				width:70%;
				text-align:left;
				display:flex;
				flex-direction:row;
				align-items:center;
		
				span{
					font-size:14px;
					color:#000;
					margin:0 30px 0 0;
				}
			}
		
			.area,
			.address{
				margin:20px 0 0 0;
				width:70%;
				text-align:left;
				display:flex;
				flex-direction:column;
		
				span{
					font-size:14px;
					color:#000;
					margin:0 0 10px 0;
				}
		
				input{
					border:1px solid #d9d9d9;
					border-radius:2px;
					height:32px;
					line-height:32px;
					padding:0 0 0 15px;
				}
			}
		
			button{
				width:65px;
				height:32px;
				background:#1890ff;
				border-radius:2px;
				color:#fff;
				margin:40px 0 0 0;
				cursor:pointer;
			}
		}

		.noticeBox {
			width: 1050px;
			margin: 0 auto;

			.box {
				margin: 15px 0 0 0;
				display: flex;
				flex-direction: row;

				.title {
					display: inline-block;
					width: 150px;
					text-align: right;
					margin: 0 30px 0 0;
				}
			}

			.box4 {
				input {
					width: 118px;
					height: 28px;
					border: 1px solid #d9d9d9;
					border-radius: 2px;
					padding: 0 0 0 5px;
				}

				.address_content {
					width: 420px;
				}
			}

			.ans {
				color: #1890FF;
				position: relative;
				cursor: pointer;
				max-width: 700px;
			}

			.ans::after {
				position: absolute;
				right: -20px;
				top: 0;
				bottom: 0;
				margin: auto;
				content: '';
				width: 7.5px;
				height: 7.5px;
				border-right: 1px solid #1890FF;
				border-bottom: 1px solid #1890FF;
				transform: rotate(-45deg);
			}

			.border {
				border-bottom: 1px solid #1890FF;
			}
		}
	}
</style>
