<template>
	<div class="sheet" v-loading="loading">
		<div class="mask" @click="prompt" v-show="showMask"></div>
		<div class="jindu" v-if="show">
			<div class='mask'></div>
			<div class="huan">
				<el-progress type="circle" :percentage="jinduNum" />
			</div>
		</div>
		<!--<TargetBox:getTargetName="getTargetName"></TargetBox>-->
		<div class="sheetBox">
			<div class="boxbox1">
				<span class="title">图片及文件上传</span>
			</div>
			<div class="summary">
				<div class="up">
					<p class="title">促销图片(至少上传{{5}}张)</p>
					<div class="li">
						<p>示例图</p>
						<div class="imageList">
							<el-image v-for="item in info.list" style="width: 100px; height: 100px" :src="item.imgurl"
								:preview-src-list="[item.imgurl]">
							</el-image>
							<!-- <img :src="item.imgurl" alt="" v-for="item in info.example.content_type1"> -->
						</div>
					</div>
					<div class="btn">
						<button class="upload-demos">上传图片
							<el-upload :action="files" :data='fileData' :on-success='upSuccess' accept='.jpg,.jpeg,.png'
								:before-upload='function(file){return onchangemd($event,2)}' class="input">
								<el-button type="primary">上传图片</el-button>
							</el-upload>
						</button><span>支持jpg/jpeg/png格式，每张图片不大于10M</span>
					</div>
					<div class="imageList">
						<div v-for="(item,index) in info.img">
							<img :src="item.imgurl" alt="">
							<img class="icon" src="../../assets/icon_7.png" alt="" @click='del(item.id,index,1)'>
						</div>
					</div>
				</div>

				<div class="up">
					<p class="title">销售明细上传<span style="color: #f00;">(非必填)</span></p>
					<div class="downLoad">
						<a href="https://tools.lyunjian.com/wx/销售明细.xlsx">点击下载表头</a>
					</div>	
					<div class="btn">
						
						<button class="upload-demos">上传文件
							<el-upload :action="files2" :data='fileData2' :on-success='upSuccess2' accept='.xlsx,.xls'
								class="input">
								<el-button type="primary">上传图片</el-button>
							</el-upload>
						</button><span>支持.xlsx,.xls格式，每个文件不大于50M</span>
					</div>
					<div class="fileList">
						<div class="fileListOne" v-if="ppt">
							<img src="../../assets/fileImg.png" alt="">
							<span>{{ppt.url2}}</span>
							<img class="icon" src="../../assets/icon_7.png" alt="" @click='del_ppt'>
						</div>
					</div>
					<!-- <div class="fen" v-show="renList2.length>0">
						<ul>
							<li>序号</li>
							<li>日期</li>
							<li>门店</li>
							<li>品种</li>
							<li>规格</li>
							<li>数量</li>
						</ul>
						<div class="tiao" v-for="(item,index) in renList2" :class="{'red':item.other == 1}">
							<div class='kuang'>
								{{index+1}}
							</div>
							<div class='kuang'>
								<span>{{item.sale_time}}</span>
							</div>
							<div class='kuang'>
								<span>{{item.shop_name}}</span>
							</div>
							<div class='kuang'>
								<span>{{item.p_name}}</span>
							</div>
							<div class='kuang'>
								<span>{{item.specs}}</span>
							</div>
							<div class='kuang'>
								<span>{{item.num}}</span>
							</div>

						</div>


					</div> -->
				</div>
			</div>

		</div>


	</div>
	<div class="save">
		<span>当前填写页</span>
		<button @click="save">保存</button>
	</div>
	<div class="sub" v-if='status_state.act_plan == 1 && status_state.act_sum == 1 && status_state.act_file == 1&&status_state.state != 1'>
		<button @click="submit">提交</button>
	</div>
</template>

<script>
	import Mask from "@/components/Mask.vue";
	//引入七牛
	import * as qiniu from 'qiniu-js'
	//引入axios
	import axios from 'axios';
	import {
		ref
	} from 'vue'
	//引入端口配置文件
	import API_LIST from '../../../list.config.js';
	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage,
		Popconfirm
	} from 'element-plus'
	import {
		provinceAndCityData,
		pcTextArr,
		regionData,
		pcaTextArr,
		codeToText,
	} from "element-china-area-data";
	export default {
		name: "SummaryKe",
		data() {
			return {
				info: {
					example: {

					}
				},
				fileList1: [],
				page: 1, //页码
				dataList: [], //数据
				imgList: [],
				files: API_LIST.uploadNew1_8, //图片
				files2: API_LIST.uploadNew_8, //文件
				filePPT: 'https://up-z2.qiniup.com',
				fileData: '',
				icon_1: '',
				uploadData: {
					token: '',
					url: 'https://up-z2.qiniup.com'
				},
				jinduNum: 0,
				show: false,
				trainingImg: [], //网络培训图片列表
				signImg: [], //签到图片列表
				meetImg: [], //会议图片列表
				ppt: '', //ppt列表
				showMask: false,
				loading: false,
				memory: {},
				m_img_num: '',
				renList2: [],
			}
		},
		components: {

		},
		props: ['status_state'],
		created() {
			//单/多选完后传回来的值
			this.xyID = this.$route.query.xyID
			this.meetID = this.$route.query.meetID
			this.sortID = this.$route.query.sortID
			this.fileData = {
				u_id: localStorage.getItem("u_id"),
				promo_id: this.meetID,
				pub_key: 'sales',
				img_type: 2, //img_type:1:方案，2：促销图片，3：总结，
				file_type: 1, //文件类型，1：图片文件，2：其他文件,3:文本
				submit_type: 2, //上传类型，1：小程序上传，2：PC上传
				type: 2, //1:线上，2：线下，3：线上+线下
			}
			this.fileData2 = {
				u_id: localStorage.getItem("u_id"),
				promo_id: this.meetID,
				pub_key: 'sales_img',
				img_type: 4, //img_type:1:方案，2：促销图片，3：总结，
				file_type: 2, //文件类型，1：图片文件，2：其他文件,3:文本
				submit_type: 2, //上传类型，1：小程序上传，2：PC上传
				type: 2, //1:线上，2：线下，3：线上+线下
			}
			if(this.status_state.state == 1 || this.status_state.act_sum != 1){
				this.showMask = true
			}else{
				console.log(this.status_state)
			}
			this.getInfo()
		},
		beforeDestroy() {

		},
		methods: {
			//提示
			prompt() {
				ElMessage({
					message: '该区域禁止更改',
					type: 'warning',
				})
			},
			//获取数据列表
			getInfo() {
				let obj = {
					u_id: localStorage.getItem("u_id"),
					promo_id: this.meetID,
				}

				axios.post(API_LIST.imgList, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						if (_d.data.status == 200) {
							console.log(_d.data.list)
							this.info = _d.data.list;
							this.ppt = _d.data.list.sales;
							// this.m_img_num = _d.data.list.m_img_num;
							// this.trainingImg = _d.data.list.list.imgs
							// this.signImg = _d.data.list.list.imgs2
							// this.meetImg = _d.data.list.list.imgs4
							// this.ppt = _d.data.list.list.ppt
							// this.memory = _d.data.list.memory
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			//上传前
			onchangemd(e, _state) {


			},
			//上传成功
			upSuccess(e) {
				if (e.status == 200) {
					this.loading = false;
					ElMessage({
						message: e.msg,
						type: 'success',
					})


					this.info.img.push(e.list)


				} else {
					this.loading = false;
					ElMessage({
						message: e.msg,
						type: 'warning',
					})
				}
			},
			//上传成功
			upSuccess2(e) {
				this.renList2 = [];
				if (e.status == 200) {
					console.log(e.list)
					// for (let i in e.list) {
					// 	this.renList2.push(e.list[i])
					// }
					this.ppt = e.list
					console.log(this.renList2[2])
				} else {
					ElMessage({
						message: e.msg,
						type: 'warning',
					})
				}
			},
			//获取
			getToken(upload) {
				const that = this;
				that.show = true;
				console.log(that.show)
				let _data = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.department_meet_id,
				}
				axios.post(API_LIST.qiniu_token, _data, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {

						if (_d.data.status == 200) {
							that.uploadData.token = _d.data.list
							that.sliderRequest(upload)
						} else {

							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			//上传
			sliderRequest(upload) {

				console.log(upload)
				// 文件上传自行处理上传
				// 创建FormData对象 添加相关上传参数
				const formData = new FormData()
				// 文件对象
				formData.append('file', upload.file);
				// key 文件名处理 images/时间戳_随机字符串.文件后缀
				const key = new Date().getFullYear() + '/' + (new Date().getMonth() + 1) + '/' + new Date().getTime() +
					this.randomString() + '' + upload.file.name
				formData.append('key', key)
				// token
				formData.append('token',
					this.uploadData.token
				)
				formData.append('bucket', 'imguzhikang')

				axios.post(this.uploadData.url, formData, {
						onUploadProgress: (event) => {
							// 监听上传进度
							event.percent = parseInt(event.loaded / event.total * 100)
							// console.log(event)
							this.jindu(event.percent)
							// upload.onProgress(event)
						}
					})
					.then(_d => {
						console.log(_d)
						this.show = false;
						if (_d.status == 200) {
							// that.show = true;
							this.submit_ppt(_d.data.key)
							// this.info.list.ppt = _d.data.key
						} else {
							// upload.onError()
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			//ppt发送给后端
			submit_ppt(_name) {
				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.meetID,
					ppt: _name
				}

				axios.post(API_LIST.submit_ppt, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {

						if (_d.data.status == 200) {
							ElMessage({
								message: _d.data.msg,
								type: 'success',
							})

							//this.info.list.imgs.splice(_ind,1)
							this.ppt.push(_d.data.list)
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			//进度
			jindu(_num) {
				this.jinduNum = _num;
			},
			//randomString
			randomString() {
				return 1
			},
			//删除ppt
			del_ppt() {
				//(999,_list)
				//return
				let obj = {
					u_id: localStorage.getItem("u_id"),
					promo_id: this.meetID,
					id: this.ppt.id,
					file_type: 2, //1是图片，2是其他文件
				}

				axios.post(API_LIST.del_img2, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {

						if (_d.data.status == 200) {
							ElMessage({
								message: _d.data.msg,
								type: 'success',
							})
							this.ppt = ''


						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			//删除图片
			del(_id, _ind, _state) {
				let obj = {
					u_id: localStorage.getItem("u_id"),
					promo_id: this.meetID,
					id: _id,
					file_type: 1, //1是图片，2是其他文件
				}

				axios.post(API_LIST.del_img2, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {

						if (_d.data.status == 200) {
							ElMessage({
								message: _d.data.msg,
								type: 'success',
							})
							if (_state == 1) {
								this.trainingImg.splice(_ind, 1)
							} else if (_state == 2) {
								this.signImg.splice(_ind, 1)
							} else if (_state == 3) {
								this.meetImg.splice(_ind, 1)
							}


						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			// 保存
			save() {
				const that = this;
				let obj = {
					u_id: localStorage.getItem("u_id"),
					promo_id: this.meetID,
					submit_type: 1,
					sales: this.renList2
				}

				for (let j = 0; j < this.renList2.length; j++) {
					if (this.renList2[j].other == 1) {
						ElMessage({
							message: '错误数据，请更改',
							type: 'warning',
						})
						return false
					}
				}


				axios.post(API_LIST.save_data_3, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						console.log(_d)
						if (_d.data.status == 200) {
							ElMessage({
								message: _d.data.msg,
								type: 'success',
							})
							setTimeout(that.$emit('changeStateUpload', ''), 1000)
						} else {
							this.renList = _d.data.list
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			// 提交
			submit(){
				const that = this;
				let obj = {
					u_id: localStorage.getItem("u_id"),
					promo_id: this.meetID,
				}
				
				axios.post(API_LIST.submit_data, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						console.log(_d)
						if (_d.data.status == 200) {
							ElMessage({
								message: _d.data.msg,
								type: 'success',
							})
							setTimeout(function(){
								that.$router.push({
									path: '/Promotion',
									query: {
										id:this.sortID
									}
								});
							}, 500)
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})
				
						}
					})
					.catch(function(error) {
				
					});
			}
		}
	}
</script>

<style scoped lang="scss">
	::v-deep .el-image-viewer__canvas img {
		transform: scale(0.7) !important;
	}

	.sheet {
		padding: 15px 0;
		background-color: #fff;
		box-shadow: 0px 0px 20px #ccc;
		margin: 5px auto;
		width: 80%;
		border-radius: 10px;
		position: relative;

		::v-deep .el-upload-dragger {
			width: 110px !important;
			height: 37px !important;
		}

		.jindu {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			overflow: hidden;
			background-color: #fff;
			opacity: 0.8;
			z-index: 1000;

			.huan {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				margin: auto;
				width: 250px;
				height: 250px;
			}
		}

		//background-color:#fff;
		//padding:15px0;
		//position:relative;

		.mask {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			z-index: 100;
			background-color: #fff;
			opacity: 0.3;
		}

		.sheetBox {
			width: 1050px;
			margin: 0 auto;

			.box {
				margin: 15px 0 0 0;
				display: flex;
				flex-direction: row;

				.title {
					display: inline-block;
					width: 160px;
					text-align: right;
					margin: 0 30px 0 0;
				}
			}

			.box1 {
				display: flex;
				flex-direction: row;
				align-items: center;
			}

			.summary {
				.up {
					display: flex;
					flex-direction: column;
					padding: 0 0 0 200px;
					box-sizing: border-box;
					margin: 10px 0;

					.title {
						padding: 15px 0;
						border-bottom: 1px solid #ddd;
					}
					.downLoad{
						margin: 15px 0 0 100px;
						a{
							color: #1890FF;
							display: inline-block;
							border-bottom: 1px solid #1890FF;
						}
					}

					.li {
						display: flex;
						flex-direction: column;
						margin: 0 0 15px 0;

						p {
							margin: 15px 0 0 100px;
						}

						img {
							width: 90px;
							height: 90px;
							margin: 0 10px 10px 0;
						}
					}

					.btn {
						display: flex;
						flex-direction: row;
						align-items: center;
						margin: 15px 0 0 100px;

						.upload-demos {
							width: 110px;
							height: 37px;
							background: #1890ff;
							color: #fff;
							text-align: center;
							line-height: 37px;
							position: relative;

							.input {
								position: absolute;
								left: 0;
								bottom: 0;
								width: 110px;
								height: 37px;
								opacity: 0;
							}
						}

						span {
							color: #000;
							opacity: 0.65;
							font-size: 14px;
							margin: 0 0 0 10px;
						}
					}

					.imageList {
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						margin: 20px 0 0 100px;

						div {
							position: relative;
							margin: 0 15px 15px 0;

							img {
								width: 90px;
								height: 90px;
							}

							.icon {
								width: 22px;
								height: 22px;
								position: absolute;
								right: -11px;
								top: -11px
							}
						}

					}

					.fileList {
						display: flex;
						flex-direction: column;
						margin: 15px 0px 0 100px;

						.fileListOne {
							margin: 0 0 10px 0;

							img {
								width: 40px;
								height: 40px;
								margin: 0px 10px 0 0px;
							}

							span {
								margin: 0px 10px 0 0px;
							}

							.icon {
								width: 30px;
								height: 30px;
								cursor: pointer;
							}
						}
					}
				}

				.fen {
					margin: 20px 0;
					display: flex;
					flex-direction: column;

					ul {
						display: flex;
						flex-direction: row;
						align-items: center;

						li {
							margin: 0 20px 0 0;
							width: 100px;
							text-align: center;
						}

						li:nth-child(2) {
							width: 150px;
						}

						li:nth-child(5) {
							width: 150px;
						}
					}

					.red {
						background-color: #f33;

					}

					.tiao {
						display: flex;
						flex-direction: row;
						align-items: center;
						margin: 10px 0 0 0;
						padding: 5px 0;

						.kuang {
							width: 100px;
							text-align: center;
							margin: 0 20px 0 0;
							display: flex;
							flex-direction: column;
							align-items: center;

							span {
								display: inline-block;
								height: 27px;
								border: 1px solid #ccc;
								width: 100px;
								text-align: center;
								line-height: 27px;
								overflow: hidden;
							}
						}

						.kuang2 {
							width: 50px;
							text-align: center;
							margin: 0 20px 0 0;

							button {
								background-color: #fff;
								color: #1890FF;
								cursor: pointer;
							}

							.del {
								background-color: #fff;
								color: #D50000;
							}
						}

						.kuang:nth-child(2) {
							width: 150px;
							overflow: hidden;
						}

						.kuang:nth-child(5) {
							width: 150px;
						}

						input {
							border: none;
							border: 1px solid #ccc;
							width: 100px;
							text-align: center;
						}

					}
				}
			}


			.ans {
				color: #1890FF;
				position: relative;
				cursor: pointer;
				max-width: 700px;
			}

			.ans::after {
				position: absolute;
				right: -20px;
				top: 0;
				bottom: 0;
				margin: auto;
				content: '';
				width: 7.5px;
				height: 7.5px;
				border-right: 1px solid #1890FF;
				border-bottom: 1px solid #1890FF;
				transform: rotate(-45deg);
			}

			.border {
				border-bottom: 1px solid #1890FF;
			}
		}

	}

	.save {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 15px 80px 15px 60px;
		background-color: #FFE9E9;
		width: 95%;
		margin: 20px auto;
		box-sizing: border-box;

		button {
			width: 65px;
			height: 32px;
			background: #ff1818;
			border-radius: 2px;
			text-align: center;
			line-height: 32px;
			color: #fff;
		}
	}

	.sub {
		padding: 15px 0;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		background-color: #FFf;
		width: 95%;
		margin: 20px auto;
		box-sizing: border-box;

		button {
			width: 65px;
			height: 32px;
			background: #1890ff;
			border-radius: 2px;
			text-align: center;
			line-height: 32px;
			color: #fff;
		}
	}
</style>
