<template>
	<div class="sheet">
		<div class="mask" @click="prompt" v-show="showMask"></div>
		<!--<AddHospitalBox></AddHospitalBox>-->
		<BudgetBox :getBudgetName="getBudgetName"></BudgetBox>
		<!--<ThemeBox:getThemeName="getThemeName"></ThemeBox>
		<TargetBox:getTargetName="getTargetName"></TargetBox>-->
		<div class="sheetBox">
			<h3>会议表单</h3>
			<div class="box box1">
				<span class="title">会议流程<span v-if="this.memory.fp_on == 2" style="color:#D50000;">({{this.memory.fp_on_name}})</span></span>
				<div class="chooseFile">
					<p :class="{'chooseBtn':radio==1}" @click='changeBtn(1)'>手动输入</p>
					<p :class="{'chooseBtn':radio==2}" @click='changeBtn(2)'>上传文件</p>
				</div>
				<!--<el-radio-groupv-model="radio">
					<el-radio:label="1">手动输入</el-radio>
					<el-radio:label="2">上传文件</el-radio>
				</el-radio-group>-->
			</div>
			<div v-show="radio==1">
				<div class="box box2" v-for="(item,index) in process" :key='index'>
					<div class="tops">
						<span class="title">流程{{index+1}}：</span>
						<div class="info">
							<div class="time">
								<span>时间:</span>
								<el-time-picker is-range v-model="item.times" size="small" :value-format="'HH:mm'"
									:format="'HH:mm'" range-separator="至" start-placeholder="开始时间"
									end-placeholder="结束时间" placeholder="选择时间范围">
								</el-time-picker>
							</div>
							<div class="speak">
								<span>发言人:</span>
								<input type="text" v-model="item.spker">
							</div>
							<div class="text">
								<span>事项:</span>
								<el-input type="textarea" :rows="2" placeholder="请输入内容"
									:autosize="{minRows:2,maxRows:4}" v-model="item.event">
								</el-input>
							</div>
						</div>
					</div>

					<div class="btn">
						<el-popconfirm title="是否确认删除此项?" @cancel='no' @confirm='yes(index)'>
							<template #reference>
								<button slot="reference" class="del">删除</button>
							</template>
						</el-popconfirm>

						<button @click="addProcess">+新增流程</button>
					</div>
				</div>
			</div>
			<div v-show="radio==2">
				<div class="up">
					<!--<pclass="title">ppt上传</p>-->
					<div class="btns">
						<!--<buttonclass="upload-demos">上传文件
							<el-uploadclass="input":multiple='false':show-file-list='false':file-list="fileList"
						:on-exceed="handleExceed"drag:data='fileData'
						:action="filePPT":http-request="getToken":on-success='upSuccessPPT':before-upload='loads'>
								<el-buttontype="primary">上传图片</el-button>
							</el-upload>
						</button>-->
						<button class="upload-demos">上传文件
							<el-upload :action="files3" :multiple='false' :data='fileData3' :on-success='upSuccess2'
								:before-upload='onchangemd2' class="input">
								<el-button type="primary">上传文件</el-button>
							</el-upload>
						</button><span>支持ppt/pdf/jpg/jpeg/png格式，每张图片不大于10M</span>
					</div>
					<div class="fileList">
						<div v-for="(item,index) in processList" class="fileListOne">
							<img src="../../assets/fileImg.png" alt="">
							<span>{{item.url2}}</span>
							<img class="icon" src="../../assets/icon_7.png" alt="" @click='del(item.id,index)'>
						</div>
					</div>
				</div>
			</div>
			<div class="box box3">
				<div>
					<span class="title">方案费用预算表<span v-if="this.memory.cost_on == 2" style="color:#D50000;">({{this.memory.cost_on_name}})</span></span>
					<span class="addTable" @click="addbudget" v-if="budgetTableList.length==0">+点击新建预算表</span>
				</div>

				<div class="table">
					<ul v-if="budgetTableList.length>0">
						<li v-for="item in budgetMenu">{{item}}</li>
					</ul>
					<ul v-for="(item,index) in budgetTableList" :key='index'>
						<li @click="budgetList(index,1)"><span>{{item.name?item.name:'请选择'}}</span></li>
						<li>
							<el-input :min="0" size="small" v-model="item.price" type="number" placeholder="请输入"
								@input="change"></el-input>
						</li>
						<li>
							<el-input :min="0" size="small" type="number" v-model="item.number" placeholder="请输入"
								@input="change"></el-input>
						</li>
						<li><span>{{Number(item.price)*Number(item.number)}}</span></li>
						<li><span>{{item.price*item.number}}</span></li>
						<li>
							<button @click="addbudget">新增</button>
							<el-popconfirm title="是否确认删除此项?" @cancel='no' @confirm='yesBudget(index)'>
								<template #reference>
									<button slot="reference" class="del">删除</button>
								</template>
							</el-popconfirm>
							<!--<button@click="delbudget(index)">删除</button>-->
						</li>
					</ul>
					<div class="total" v-if="budgetTableList.length>0">
						<div>
							<span>费用合计</span>
							<span>{{budgetTotal}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="box box3">
				<div>
					<span class="title">方案费用结算表<span v-if="this.memory.cost_on == 2" style="color:#D50000;">({{this.memory.cost_on_name}})</span></span>
					<span class="addTable" @click="addsettlement" v-if="settlementTableList.length==0">+
						点击新建结算表</span>
				</div>

				<div class="table">
					<ul v-if="settlementTableList.length>0">
						<li v-for="item in budgetMenu">{{item}}</li>
					</ul>
					<ul v-for="(item,index) in settlementTableList" :key='index'>
						<li @click="budgetList(index,2)"><span>{{item.name?item.name:'请选择'}}</span></li>
						<li>
							<el-input :min="0" size="small" v-model="item.price" type="number" placeholder="请输入"
								@input="change"></el-input>
						</li>
						<li>
							<el-input :min="0" size="small" type="number" v-model="item.number" placeholder="请输入"
								@input="change"></el-input>
						</li>
						<li><span>{{item.price*item.number}}</span></li>
						<li><span>{{item.price*item.number}}</span></li>
						<li>
							<button @click="addsettlement">新增</button>
							<el-popconfirm title="是否确认删除此项?" @cancel='no' @confirm='yesSettlement(index)'>
								<template #reference>
									<button slot="reference" class="del">删除</button>
								</template>
							</el-popconfirm>
							<!--<button@click="delsettlement(index)">删除</button>-->
						</li>
					</ul>
					<div class="total" v-if="settlementTableList.length>0">
						<div>
							<span>费用合计</span>
							<span>{{settlementTotal}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import Mask from "@/components/Mask.vue";
	import BudgetBox from "@/components/BudgetBox.vue";
	//引入axios
	import axios from 'axios';
	import {
		ref
	} from 'vue'
	//引入端口配置文件
	import API_LIST from '../../../list.config.js';
	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage,
		Popconfirm
	} from 'element-plus'
	import {
		provinceAndCityData,
		pcTextArr,
		regionData,
		pcaTextArr,
		codeToText,
	} from "element-china-area-data";
	export default {
		name: "SheetKe",
		data() {
			return {
				showMask: true,
				radio: '',
				process: [{
					times: [],
					spker: '',
					event: '',
				}],
				budgetTableList: [], //预算表列表
				budgetTotal: 0, //预算表费用合计
				budgetTotalBig: '', //预算表费用合计大写
				settlementTableList: [], //结算表列表
				budgetMenu: ['费用项目', '单价', '数量', '单位费用', '预算费用金额'], //预算表展示

				//上传
				dataList: [], //数据
				imgList: [],
				files: API_LIST.uploadNew, //图片
				files2: API_LIST.uploadNew2, //文件
				files3: API_LIST.upload_process6, //文件
				processList: [], //文件列表
				fileData: '',
				fileData3: {

				},
				memory:'', // 保存是否必填的判断
			}
		},
		components: {
			BudgetBox,
		},
		created() {
			
			
			// this.$eventBus.off('allow')
			//遮罩层
			console.log('----------')
			this.$eventBus.on('allow', data => {
				console.log(313213)
				this.showMask = !data.allowData
			})
			this.xyID = this.$route.query.xyID
			this.meetID = this.$route.query.meetID
			this.meetType = this.$route.query.meetType
			this.fileData = {
				u_id: localStorage.getItem("u_id"),
				department_meet_id: this.meetID,
				pub_key: 'summary',
				img_type: '', //1:会后总结，2：线上培训图片，3：签到图片，4.线下培训图片
				file_type: 2, //文件类型，1：图片文件，2：其他文件,3:文本
				submit_type: 2, //上传类型，1：小程序上传，2：PC上传
				type: this.meetType, //1:线上，2：线下，3：线上+线下
			}
			this.fileData3 = {
				u_id: localStorage.getItem("u_id"),
				department_meet_id: this.meetID,
				process_id: '',
				type: 2
			}
			this.getListInfo()
			this.getWriteInfo()
		},
		beforeDestroy() {
			this.$eventBus.off('allow')
		},
		methods: {
			//提示
			prompt() {
				ElMessage({
					message: '请先完成会议通知板块内容',
					type: 'warning',
				})
			},
			//获取产品列表
			getListInfo() {
				let obj = {
					u_id: localStorage.getItem("u_id"),
					agreement_id: this.xyID,
					p_type: 1
				}
				let a = qs.stringify(obj)

				axios.post(API_LIST.tg_product_list, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {


						if (_d.data.status == 200) {
							this.productList = _d.data.list
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			//获取已经输入的值
			getWriteInfo() {
				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.meetID,
				}
				let a = qs.stringify(obj)

				axios.post(API_LIST.update_pcmeet_page1_6, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {


						if (_d.data.status == 200) {
							//this.productList=_d.data.list
							this.radio = _d.data.list.process_type
							this.memory = _d.data.list.memory
							if (_d.data.list.process_type == 1) {
								this.process = _d.data.list.process
							} else {
								this.processList = _d.data.list.process
							}
							_d.data.list.expense_item_arr1.forEach(item => {
								this.budgetTableList.push({
									expense_item_id: item.expense_item_id,
									name: item.expense_item_name,
									price: item.unit,
									number: item.num,
								})
							})
							_d.data.list.expense_item_arr2.forEach(item => {
								this.settlementTableList.push({
									expense_item_id: item.expense_item_id,
									name: item.expense_item_name,
									price: item.unit,
									number: item.num,
								})
							})
							this.change()



						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			//更改选择
			changeBtn(state) {
				this.radio = this.radio == state ? '' : state
				//(e)
			},
			//删除流程
			delLiu(_ind) {
				if (this.process.length > 1) {
					this.process.splice(_ind, 1)
				} else {
					ElMessage({
						message: '仅剩一个时不可删除',
						type: 'warning',
					})
				}

			},
			//新增流程
			addProcess() {
				this.process.push({
					times: [],
					spker: '',
					event: '',
				})
			},
			//确认删除
			yes(_ind) {
				this.delLiu(_ind)

			},
			//取消删除
			no() {

			},
			
			// 
			onchangemd2(e){
				
			},

			//新建/新增预算表
			addbudget() {
				this.budgetTableList.push({
					expense_item_id: '',
					name: '',
					price: '',
					number: '',
				})
			},
			//新建/新增结算表
			addsettlement() {
				this.settlementTableList.push({
					expense_item_id: '',
					name: '',
					price: '',
					number: '',
				})
			},
			//删除预算表
			yesBudget(_ind) {
				this.budgetTableList.splice(_ind, 1)
				this.change()
			},
			//删除结算表
			yesSettlement(_ind) {
				this.settlementTableList.splice(_ind, 1)
				this.change()
			},
			//重新赋值以达到响应式
			change() {
				this.budgetTotal = 0;
				this.settlementTotal = 0;
				this.budgetTableList = this.budgetTableList
				this.budgetTableList.forEach(item => {
					this.budgetTotal = this.budgetTotal + item.price * item.number
				})
				this.settlementTableList = this.settlementTableList
				this.settlementTableList.forEach(item => {
					this.settlementTotal = this.settlementTotal + item.price * item.number
				})

			},
			//跳转到费用列表
			budgetList(_ind, _type) {
				this.$eventBus.emit('showBudgetBox', {
					type: _type, //预算1，结算2
					show: true,
					ind: _ind
				})
			},
			//获取选择的费用项目
			getBudgetName(_data) {

				if (_data.type == 1) {
					this.budgetTableList[_data.ind].expense_item_id = _data.name.id
					this.budgetTableList[_data.ind].name = _data.name.name
				}
				else if(_data.type == 2) {
					this.settlementTableList[_data.ind].expense_item_id = _data.name.id
					this.settlementTableList[_data.ind].name = _data.name.name
				}

			},
			//上传文件
			upSuccess2(e) {
				if (e.status == 200) {
					ElMessage({
						message: e.msg,
						type: 'success',
					})

					this.processList.push(e.list)

				} else {
					ElMessage({
						message: e.msg,
						type: 'warning',
					})
				}
			},
			//删除图片
			del(_id, _ind) {
				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.meetID,
					process_id: _id,
				}

				axios.post(API_LIST.del_img, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {

						if (_d.data.status == 200) {
							ElMessage({
								message: _d.data.msg,
								type: 'success',
							})
							this.processList.splice(_ind, 1)
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {

					});
			},
			//阿拉伯数字转换大写
			numberParseChina(money) {
				//汉字的数字
				var cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
				//基本单位
				var cnIntRadice = ['', '拾', '佰', '仟'];
				//对应整数部分扩展单位
				var cnIntUnits = ['', '万', '亿', '兆'];
				//对应小数部分单位
				var cnDecUnits = ['角', '分', '毫', '厘'];
				//整数金额时后面跟的字符
				var cnInteger = '整';
				//整型完以后的单位
				var cnIntLast = '元';
				//最大处理的数字
				var maxNum = 999999999999999.9999;
				//金额整数部分
				var integerNum;
				//金额小数部分
				var decimalNum;
				//输出的中文金额字符串
				var chineseStr = '';
				//分离金额后用的数组，预定义
				var parts;
				if (money == '') {
					return '';
				}
				money = parseFloat(money);
				if (money >= maxNum) {
					//超出最大处理数字
					return '';
				}
				if (money == 0) {
					chineseStr = cnNums[0] + cnIntLast + cnInteger;
					returnchineseStr;
				}
				//转换为字符串
				money = money.toString();
				if (money.indexOf('.') == -1) {
					integerNum = money;
					decimalNum = '';
				} else {
					parts = money.split('.');
					integerNum = parts[0];
					decimalNum = parts[1].substr(0, 4);
				}
				//获取整型部分转换
				if (parseInt(integerNum, 10) > 0) {
					var zeroCount = 0;
					var IntLen = integerNum.length;
					for (var i = 0; i < IntLen; i++) {
						var n = integerNum.substr(i, 1);
						var p = IntLen - i - 1;
						var q = p / 4;
						var m = p % 4;
						if (n == '0') {
							zeroCount++;
						} else {
							if (zeroCount > 0) {
								chineseStr += cnNums[0];
							}
							//归零
							zeroCount = 0;
							chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
						}
						if (m == 0 && zeroCount < 4) {
							chineseStr += cnIntUnits[q];
						}
					}
					chineseStr += cnIntLast;
				}
				//小数部分
				if (decimalNum != '') {
					var decLen = decimalNum.length;
					for (var i = 0; i < decLen; i++) {
						var n = decimalNum.substr(i, 1);
						if (n != '0') {
							chineseStr += cnNums[Number(n)] + cnDecUnits[i];
						}
					}
				}
				if (chineseStr == '') {
					chineseStr += cnNums[0] + cnIntLast + cnInteger;
				} else if (decimalNum == '') {
					chineseStr += cnInteger;
				}
				return chineseStr;
			}
		}
	}
</script>

<style scoped lang="scss">
	.sheet {
		background-color: #fff;
		padding: 15px 0;
		position: relative;
		// margin:15px 0;
		box-shadow:0px 0px 20px #ccc;
		margin: 5px auto;
		width: 80%;
		border-radius: 10px;

		.mask {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			z-index: 100;
			background-color: #fff;
			opacity: 0.3;
		}

		.sheetBox {
			width: 1050px;
			margin: 0 auto;

			.box {
				margin: 15px 0 0 0;
				display: flex;
				flex-direction: row;

				.title {
					display: inline-block;
					width: 150px;
					text-align: right;
					margin: 0 30px 0 0;
				}

				.chooseFile {
					display: flex;
					flex-direction: row;
					align-items: center;

					p:nth-child(1),
					p:nth-child(2) {
						margin: 0 40px;
						position: relative;
						cursor: pointer;
					}

					p:nth-child(1)::before,
					p:nth-child(2)::before {
						content: '';
						width: 13px;
						height: 13px;
						border: 1px solid #aaa;
						position: absolute;
						left: -20px;
						top: 0;
						bottom: 0;
						margin: auto;
						border-radius: 50%;
					}

					.chooseBtn::before {
						border: 1px solid #1890FF !important;
					}

					.chooseBtn::after {
						border-left: 1px solid #1890FF;
						border-bottom: 1px solid #1890FF;
						content: '';
						width: 6px;
						height: 3px;
						position: absolute;
						left: -16px;
						top: 0;
						bottom: 0;
						margin: auto;
						transform: rotate(-50deg);
					}
				}
			}

			.box1 {
				display: flex;
				flex-direction: row;
				align-items: center;
			}

			.box2 {
				display: flex;
				flex-direction: column;

				.tops {
					display: flex;
					flex-direction: row;
				}
			}

			.up {
				display: flex;
				flex-direction: column;
				padding: 0 00 0 0px;
				box-sizing: border-box;
				margin: 10px 0;

				.title {
					padding: 15px 0;
					border-bottom: 1px solid #ddd;
				}

				.li {
					display: flex;
					flex-direction: column;
					margin: 0 0 15px 0;

					p {
						margin: 15px 0 0 100px;
					}

					img {
						width: 90px;
						height: 90px;
						margin: 0 10px 10px 0;
					}
				}

				.btns {
					display: flex;
					flex-direction: row;
					align-items: center;
					margin: 15px 0 0 200px;

					.upload-demos {
						width: 110px;
						height: 37px;
						background: #1890ff;
						color: #fff;
						text-align: center;
						line-height: 37px;
						position: relative;

						.input {
							position: absolute;
							left: 0;
							bottom: 0;
							width: 110px;
							height: 37px;
							opacity: 0;
						}
					}

					span {
						color: #000;
						opacity: 0.65;
						font-size: 14px;
						margin: 0 0 0 10px;
					}
				}

				.fileList {
					display: flex;
					flex-direction: column;
					margin: 15px 0px 0 200px;

					.fileListOne {
						margin: 0 0 10px 0;

						img {
							width: 40px;
							height: 40px;
							margin: 0px 10px 0 0px;
						}

						span {
							margin: 0px 10px 0 0px;
						}

						.icon {
							width: 30px;
							height: 30px;
							cursor: pointer;
						}
					}
				}
			}

			.box3 {
				display: flex;
				flex-direction: column;

				.addTable {
					width: 130px;
					height: 22px;
					font-size: 14px;
					color: #1890ff;
					line-height: 22px;
					text-align: center;
					cursor: pointer;
				}

				.table {
					display: flex;
					flex-direction: column;
					margin: 30px 0 0 100px;

					ul {
						display: flex;
						flex-direction: row;
						align-items: center;
						margin: 0 0 10px 0;

						li {
							margin: 0 20px 0 0;
							height: 30px;
						}

						li:nth-child(1) {
							width: 118px;
							text-align: center;
						}

						li:nth-child(2) {
							width: 118px;
							text-align: center;
						}

						li:nth-child(3) {
							width: 118px;
							text-align: center;
						}

						li:nth-child(4) {
							width: 118px;
							text-align: center;
						}

						li:nth-child(5) {
							width: 118px;
							text-align: center;
						}

						li:nth-child(6) {
							width: 118px;
							text-align: center;

							button:nth-child(1) {
								width: 53px;
								height: 28px;
								background: #e0f1ff;
								border-radius: 3px;
								color: #1890FF;
								margin: 0 10px 0 0;
							}

							.del {
								width: 53px;
								height: 28px;
								background: #f5c3c3;
								border-radius: 3px;
								color: #D50000;
							}
						}

						input {
							width: 100%;
							height: 25px;
							border: 1px solid #ccc;
							text-align: center;
						}

						span {
							display: inline-block;
							height: 30px;
							width: 100%;
							border: 1px solid #DCDFE6;
							border-radius: 3px;
							text-align: center;
							//height:100%;
							line-height: 30px;
							cursor: pointer;
						}
					}

					.total {
						display: flex;
						flex-direction: row;
						align-items: flex-end;
						justify-content: flex-end;
						box-sizing: border-box;
						padding: 0 160px 0 0;

						div {
							min-width: 110px;
							display: flex;
							flex-direction: column;

							span {
								color: #000;
								font-size: 14px;
								min-width: 110px;
								display: inline-block;
								text-align: center;
								margin: 15px 0 0 0;
							}
						}

						//align-items:center;

					}
				}
			}

			.box4 {
				input {
					width: 118px;
					height: 28px;
					border: 1px solid #d9d9d9;
					border-radius: 2px;
					padding: 0 0 0 5px;
				}
			}

			.info {
				display: flex;
				flex-direction: column;
				margin: 20px 0 0 0;

				.time {
					span {
						margin: 0 10px 0 0;
						width: 50px;
						text-align: right;
						display: inline-block;
					}
				}

				.speak {
					margin: 20px 0;
					display: flex;
					flex-direction: row;

					//align-items:center;
					span {
						margin: 0 10px 0 0;
						width: 50px;
						text-align: right;
						display: inline-block;
					}

					input {
						width: 234px;
						height: 32px;
						border: 1px solid #d9d9d9;
						border-radius: 2px;
						padding: 0 10px;
					}
				}

				.text {
					display: flex;
					flex-direction: row;

					//align-items:center;
					span {
						margin: 0 10px 0 0;
						width: 50px;

						text-align: right;
						display: inline-block;
					}

					.el-textarea {
						width: 350px !important;
					}
				}
			}

			.btn {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin: 20px 0;

				//justify-content:center;
				button:nth-child(1) {
					color: #D50000;
					margin: 0 100px 0 280px;
					cursor: pointer;
				}

				button:nth-child(2) {
					width: 97px;
					height: 28px;
					background: #e0f1ff;
					border-radius: 3px;
					color: #1890FF;
					cursor: pointer;
				}
			}

			.ans {
				color: #1890FF;
				position: relative;
				cursor: pointer;
				max-width: 700px;
			}

			.ans::after {
				position: absolute;
				right: -20px;
				top: 0;
				bottom: 0;
				margin: auto;
				content: '';
				width: 7.5px;
				height: 7.5px;
				border-right: 1px solid #1890FF;
				border-bottom: 1px solid #1890FF;
				transform: rotate(-45deg);
			}

			.border {
				border-bottom: 1px solid #1890FF;
			}
		}
	}
</style>
