<template>
	<div class="about" onselectstart="return false">
		<div class="mask" v-show="showMask"></div>
		<Titles></Titles>
		<!-- 医院弹出框 -->
		<!-- <HospitalBox></HospitalBox> -->
		<div class="box">
			<div class="titles">所属科目：<span>贴柜培训会</span></div>
		</div>
		<NoticeTie ref='notice' @closes='closes' @show="show"></NoticeTie>
		<!-- <SheetKe ref='sheet'></SheetKe> -->
		<ProblemTie ref='problem'></ProblemTie>
		<SummaryTie ref='summary'></SummaryTie>
		<PlanTie ref='plan'></PlanTie>
		<UpfileTie ref='upfile'></UpfileTie>
		<div class="sub" v-if="!showMask">
			<button class='btn' @click='save'>保存</button>
			<button class='btn2' @click='submit'>提交</button>
		</div>
		<div class="mask2" v-show="showMask2"></div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import NoticeTie from "@/components/Stick/NoticeTie.vue";
	import SheetKe from "@/components/SheetKe.vue";
	import ProblemTie from "@/components/Stick/ProblemTie.vue";
	import Titles from "@/components/titles.vue";
	import HospitalBox from "@/components/HospitalBox.vue";
	import SummaryTie from "@/components/Stick/SummaryTie.vue";
	import PlanTie from "@/components/Stick/PlanTie.vue";
	import UpfileTie from "@/components/Stick/UpfileTie.vue";
	import axios from 'axios';

	import {

		ref

	} from 'vue'

	// 引入端口配置文件

	import API_LIST from '../../list.config.js';

	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage,
		Popconfirm
	} from 'element-plus'

	export default {
		name: "Hui",
		data() {
			return {
				showMask: true,
			}
		},
		components: {
			Titles,
			NoticeTie,
			HospitalBox,
			SheetKe,
			ProblemTie,
			SummaryTie,
			PlanTie,
			UpfileTie
		},
		created() {
			this.meetID = this.$route.query.meetID
			this.getState()
		},
		methods: {
			// 获取科室会状态
			getState() {
				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.meetID,
				}

				let a = qs.stringify(obj)

				axios.post(API_LIST.de_state, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						console.log(777, _d)

						if (_d.data.status == 200) {
							this.showMask = _d.data.list.state == 1 ? true : false
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			//
			show(){
				this.showMask2 = true;
			},
			// 
			closes(){
				this.showMask2 = false;
			},
			// 保存
			save() {
				console.log('---', this.$refs.upfile)
				// let depart_process = {}
				// let expense_item_arr1 = {
				// 	type: 1,
				// 	result: [],
				// 	total: this.$refs.sheet.budgetTotal,
				// 	bese: this.$refs.sheet.numberParseChina(this.$refs.sheet.budgetTotal)
				// }
				// let expense_item_arr2 = {
				// 	type: 2,
				// 	result: [],
				// 	total: this.$refs.sheet.settlementTotal,
				// 	bese: this.$refs.sheet.numberParseChina(this.$refs.sheet.settlementTotal)
				// }
				let meet_question = this.$refs.problem.queList
				// 效果及评价
				let meet_se = {
					type2: this.$refs.summary.radio == 1 ? 1 : this.$refs.summary.radio == 2 ? 2 : '',
					answer: []
				}

				// 跟进及计划
				let meet_se_ed = {
					answer: []
				}



				// 会议中遇到的问题判定
				if (meet_question.length == 1 && !meet_question[0].title && !meet_question[0].content) {
					meet_question = []
				} else {
					try {
						meet_question.forEach(item => {
							if (item.title == '' || item.content == '') {
								ElMessage({
									message: '会议中遇到问题互动及解答请填写完整',
									type: 'error',
								})
								throw new Error('End Loop')
							}

						})
					} catch (e) {
						if (e.message === 'End Loop') throw e
					}
				}





				// 
				if (this.$refs.summary.radio == 1) {


					if (this.$refs.summary.addSummaryList.length > 0) {
						try {
							this.$refs.summary.addSummaryList.forEach(item => {
								if (!item.title) {
									ElMessage({
										message: '新增的会后总结会议效果及评价请填写完整',
										type: 'error',
									})
									throw new Error('End Loop')
								}
								meet_se.answer.push({
									title: item.title,
									content: '',
									words: 'data2',
									id: item.id
								})
							})
						} catch {
							if (e.message === 'End Loop') throw e
						}


					}

				}


				if (this.$refs.plan.addSummaryList.length > 0) {
					try {
						this.$refs.plan.addSummaryList.forEach(item => {
							if (!item.title) {
								ElMessage({
									message: '新增的会后跟进及计划请填写完整',
									type: 'error',
								})
								throw new Error('End Loop')
							}
							meet_se_ed.answer.push({
								title: item.title,
								content: '',
								words: 'data2',
								id: item.id
							})
						})
					} catch (e) {
						if (e.message === 'End Loop') throw e
					}


				}


				// this.$refs.summary.
				// 
				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.meetID,
					submit_type: 1,
					p_type: 1,
					// depart_process, // 会议流程
					// expense_item_arr1, // 预算表
					// expense_item_arr2, // 结算表
					meet_question, // 会议中遇到的问题及解答
					meet_se, // 会后总结效果及评价
					meet_se_ed, // 会后跟进效果
				}

				let a = qs.stringify(obj)

				axios.post(API_LIST.save_data2, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						// console.log(11, _d)

						if (_d.data.status == 200) {
							ElMessage({
								message: _d.data.msg,
								type: 'success',
							})
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {
						console.log(error);
					});

			},
			// 提交
			submit() {
				console.log('---', this.$refs.upfile)

				let meet_question = this.$refs.problem.queList
				// 效果及评价
				let meet_se = {
					type2: this.$refs.summary.radio == 1 ? 1 : this.$refs.summary.radio == 2 ? 2 : '',
					answer: [],
					answer2: []
				}

				// 跟进及计划
				let meet_se_ed = {
					answer: [],
					answer2: []
				}
				
				// 会议中遇到的问题判定
				if (meet_question.length == 1 && !meet_question[0].title && !meet_question[0].content) {
					meet_question = []
				} else {
					try {
						meet_question.forEach(item => {
							if (item.title == '' || item.content == '') {
								ElMessage({
									message: '会议中遇到问题互动及解答请填写完整',
									type: 'error',
								})
								throw new Error('End Loop')
							}
				
						})
					} catch (e) {
						if (e.message === 'End Loop') throw e
					}
				}



				// 
				if (this.$refs.summary.radio == 1) {

					if (this.$refs.summary.addSummaryList.length > 0) {
						try {
							this.$refs.summary.addSummaryList.forEach(item => {
								if (!item.title) {
									ElMessage({
										message: '新增的会后总结会议效果及评价请填写完整',
										type: 'error',
									})
									throw new Error('End Loop')
								}
								meet_se.answer.push({
									title: item.title,
									content: '',
									words: 'data2',
									id: item.id
								})

							})
						} catch {
							if (e.message === 'End Loop') throw e
						}


					} else {
						ElMessage({
							message: '请填写会后总结会议效果及评价',
							type: 'error',
						})
						return
					}

				}


				if (this.$refs.plan.addSummaryList.length > 0) {
					try {
						this.$refs.plan.addSummaryList.forEach(item => {
							if (!item.title) {
								ElMessage({
									message: '新增的会后跟进及计划请填写完整',
									type: 'error',
								})
								throw new Error('End Loop')
							}
							meet_se_ed.answer.push({
								title: item.title,
								content: '',
								words: 'data2',
								id: item.id
							})
						})
					} catch (e) {
						if (e.message === 'End Loop') throw e
					}
				} else {
					ElMessage({
						message: '请填写会后跟进及计划',
						type: 'error',
					})
					return
				}


				// 判断文件
				
				if (this.$refs.upfile.meetType == 2 || this.$refs.upfile.meetType == 3) {
					
					if (this.$refs.upfile.meetImg.length < Number(this.$refs.upfile.m_img_num)) {
						ElMessage({
							message: '会议图片至少上传'+this.$refs.upfile.m_img_num+'张',
							type: 'error',
						})
						return
					}
				}
				

				let obj = {
					u_id: localStorage.getItem("u_id"),
					department_meet_id: this.meetID,
					submit_type: 2,
					p_type: 1,
					meet_question, // 会议中遇到的问题及解答
					meet_se, // 会后总结效果及评价
					meet_se_ed, // 会后跟进效果
				}

				let a = qs.stringify(obj)

				axios.post(API_LIST.save_data2, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						// console.log(11, _d)

						if (_d.data.status == 200) {
							ElMessage({
								message: _d.data.msg,
								type: 'success',
							})
							this.$router.go(-1)

						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},
		}
	};
</script>
<style lang="scss" scoped>
	.about {
		height: 100%;

	}

	.mask,.mask2 {
		position: fixed;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		z-index: 100;
		background-color: #fff;
		opacity: 0.3;
	}
	.mask2{
		background-color: #000;
	}

	.box {
		margin: 15px 0;
		padding: 25px 0;
		background-color: #fff;

		.titles {
			width: 1050px;
			margin: 0 auto;
			font-size: 20px;
			font-weight: 800;
		}
	}

	.sub {
		padding: 40px 0;
		background-color: #fff;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		box-shadow: 0px 0px 20px #ccc;
		margin: 5px auto;
		width: 80%;
		border-radius: 10px;
		position: relative;
	}

	.btn,
	.btn2 {
		width: 65px;
		height: 32px;
		background: #1890ff;
		text-align: center;
		line-height: 32px;
		margin: 0 0 0 55px;
		color: #fff;
		border-radius: 10px;
		// margin: 0 auto;
	}

	.btn {
		background-color: #fff;
		border: 1px solid #1890ff;
		color: #1890ff;
	}
</style>
