<template>
	<div class="hello">
		<!-- <div class="head">
			<div class="logo">
				<img src='../assets/logo.png' />
				<span>{{info.user_name}}</span>
			</div>
			<div class="close" @click="quit">
				<img src="../assets/close.png" />
				<span>退出</span>
			</div>
		</div> -->
		<titles />
	</div>
	<div style="position: relative;">
		<div class='title'>
			<p>下载流向表</p>
			<!-- <span>上传对应图片</span> -->
		</div>
		<div class="image">
			<a :href="liu" style="cursor: pointer;"><el-button type="primary">点击下载</el-button></a>
		</div>
	</div>
	<div style="position: relative;border-top: 20px solid #eee;">
		<div class='title'>
			<p>上传流向表</p>
			<span>上传对应信息</span>
		</div>
		<div class="image2">
			<p class="titles"><label>*</label>流向上传</p>
			<!-- <div class="up" v-if="info.list.meet_content != 1  && !summary && info.list.imgs.length==0"> -->
			<div class="up">
				<div class="btn">
					<button class="upload-demos">上传文件
						<el-upload :action="files" :multiple='false' :data='fileData' :on-success='upSuccess'
							:before-upload='onchangemd' class="input" accept=".xlsx,.xls">
							<el-button type="primary">上传文件</el-button>
						</el-upload>
					</button><span style="color: #f00;">支持.xlsx,.xls格式，单个文件不大于10M</span>
				</div>

			</div>
		</div>
		<!-- <div class="ppt">
			<p class="titles"><label>*</label>PPT上传<span class="el-upload__tip" style="color: #f00;opacity: 0.75;">
					支持扩展名：ppt、pdf
				</span></p>

			<el-upload class="upload-demo" :multiple='false' :show-file-list='false' :file-list="fileList1" :limit="1"
				:on-exceed="handleExceed" drag :accept='[info.user_type == 2?".pdf":".ppt,.pdf"]' :data='fileData'
				:action="filePPT" :http-request="getToken" :on-success='upSuccessPPT' :before-upload='loads'>
				<el-icon class="el-icon--upload">
					<img src="../assets/icon_8.png" alt="">
				</el-icon>
				<div class="el-upload__text">
					将文件拖到此处或点击上传
				</div>
				<template #tip>
					<a :href="info.list.ppt" style="cursor: pointer;" v-if="info.list.ppt">已上传ppt，点击查看</a>
					<span class="dels" style="margin: 0 0pt 0pt 10px; color:#f00;opacity: 0.75;cursor: pointer;"
						v-if="info.list.ppt" @click="del_ppt(fileList1)">点击删除</span>
				</template>
			</el-upload>
		</div>
		<div class="jindu" v-if="show">
			<div class='mask'></div>
			<div class="huan">
				<el-progress type="circle" :percentage="jinduNum" />
			</div>
		</div> -->
		<!-- <div class='mask' v-if="this.info.list.state == 1"></div> -->
	</div>

	<div class="sub">
		<button @click="back">返回</button>
		<!-- <button @click="sub" v-if="this.info.list.state != 1 && this.info.list.img || this.info.list.meet_content || this.info.list.other_file || this.info.list.ppt">更改</button> -->
		<button @click="back">提交</button>
	</div>
	<div class="tan" v-if="show">
		<div><img src="../assets/icon_10.png" alt=""><span>提示</span></div>
		<div>{{errorList}}</div>
		<div><span @click="close">确定</span></div>
	</div>
	<div class="mask" v-if="show"></div>

</template>


<script>
	// 引入七牛
	import * as qiniu from 'qiniu-js'
	// 引入axios
	import axios from 'axios';
	// 引入端口配置文件
	import API_LIST from '../../list.config.js';

	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import Titles from "@/components/titles.vue";


	export default {
		name: "HelloWorld",
		components:{
			Titles,
		},
		props: {
			
		},
		data() {
			return {
				liu:'https://tools.lyunjian.com/wx/流向表头.xlsx',
				files: API_LIST.importExecl, // 文件
				fileData: {
					name:'excelfile',
					u_id: localStorage.getItem("u_id"),
				},
				info:{
					user_name:''
				},
				errorList:'',
				show:false,
			}

		},
		watch: {
			// summary(newData) {
			// 	console.log(newData)
			// 	const that = this;
			// 	console.log(this.info.list.text)
			// 	if (!this.info.list.text) {
			// 		return
			// 	}
			// 	if (newData) {
			// 		return
			// 	}
			// 	let obj = {
			// 		u_id: localStorage.getItem("u_id"),
			// 		department_meet_id: this.department_meet_id,
			// 		img_id: this.info.list.text.id
			// 	}

			// 	axios.post(API_LIST.del_content, obj, {
			// 			headers: {
			// 				'Content-Type': 'application/json'
			// 			} //加上这个
			// 		})
			// 		.then(_d => {
			// 			console.log(_d)
			// 			if (_d.data.status == 200) {
			// 				console.log(_d.data.msg)
			// 			} else {
			// 				ElMessage({
			// 					message: _d.data.msg,
			// 					type: 'warning',
			// 				})

			// 			}
			// 		})
			// 		.catch(function(error) {
			// 			console.log(error);
			// 		});

			// }
		},
		beforeCreate () {
		  document.querySelector('body').setAttribute('style', 'background-color:#fff;')
		},
		 
		beforeDestroy () {
		  document.querySelector('body').removeAttribute('style')
		},

		created() {

		},
		mounted() {
			if (!localStorage.getItem("u_id")) {
				this.$router.push({
					path: '/',
					query: {
			
					}
				});
			} else {
				// this.department_meet_id = this.$route.query.department_meet_id
				// this.state = this.$route.query.state
			
				console.log(this.$route.query)
				// this.getInfo()
			}
		},
		methods: {
			// 返回
			back() {
				this.$router.go(-1)
			},
			// 获取数据列表
			// getInfo() {
			// 	let obj = {
			// 		u_id: localStorage.getItem("u_id"),
			// 		department_meet_id: this.department_meet_id,
			// 	}
			
			// 	axios.post(API_LIST.pc_submit_page, obj, {
			// 			headers: {
			// 				'Content-Type': 'application/json'
			// 			} //加上这个
			// 		})
			// 		.then(_d => {
			// 			console.log(_d)
			// 			if (_d.data.status == 200) {
			// 				this.info = _d.data.list;
			
			// 			} else {
			// 				ElMessage({
			// 					message: _d.data.msg,
			// 					type: 'warning',
			// 				})
			
			// 			}
			// 		})
			// 		.catch(function(error) {
			// 			console.log(error);
			// 		});
			// },
			// 上传前
			onchangemd(e) {
				console.log(e)
				const that = this;
				this.load2 = this.$loading({
					fullscreen: true,
					text:'导入中......'
				})
				// 以检查文件是否为图片
				// const fileName = e.name;
				// const fileType = fileName.substring(fileName.lastIndexOf('.'));
				// if (fileType === '.jpg' || fileType === '.png' || fileType === '.jpeg') {
				// 	console.log('是图片')
				// 	that.fileData.file_type = 1;
				// } else {
				// 	console.log('不是.jpg文件');
				// 	that.fileData.file_type = 2;
				// }
			
				console.log(that.fileData)
			},
			// 上传成功
			upSuccess(e) {
				this.load2.close()
				console.log(111, e)
				if (e.status == 200) {
					ElMessage({
						message: e.msg,
						type: 'success',
					})
					// this.info.list.other_file.push(e.list)
				}else if(e.status == 406) {
					this.errorList = e.msg+e.list.join(',')
					this.show = true;
					
				} else {
					// this.errormsg = 
					ElMessage({
						message: e.msg,
						type: 'warning',
					})
					// ElMessage({
					// 	message: e.list.join(',') + e.msg,
					// 	type: 'warning',
					// })
				}
			},
			// 关闭弹窗
			close(){
				this.show = false;
			}
			
		}
	};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.hello{
		background-color: #fff;
	}
	.jindu {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		background-color: #fff;
		opacity: 0.8;
		z-index: 1000;

		.huan {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			margin: auto;
			width: 250px;
			height: 250px;
		}
	}

	.hui {
		background-color: #ccc !important;
		color: #000 !important;
	}

	.mask {
		width: 100%;
		height: 100%;
		background-color: #000;
		opacity: 0.3;
		left: 0;
		top: 0;
		position: absolute;
		z-index: 1000000;
	}

	.head {
		height: 48px;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		border-bottom: 1px solid #f0f2f5;

		.logo,
		.close {
			img {
				height: 26px;
			}

			span {
				margin: 0 25px 0 10px;
				font-size: 14px;
			}
		}

		.close {
			span {
				color: #0073FF;
			}
		}

		.close:hover {
			cursor: pointer;
		}
	}

	.title {
		width: 100%;
		padding: 30px 150px;
		box-sizing: border-box;
		border-bottom: 20px solid #f0f2f5;

		p {
			font-size: 20px;
			font-weight: 800;
			margin: 0 0 5px 0;
		}

		span {

			font-size: 14px;
			font-weight: 500;
			color: #000;
			// opacity: 0.65;
		}
	}

	
	.image,
	.image2,
	.summary,
	.ppt {
		display: flex;
		flex-direction: column;
		padding: 0 150px;
		box-sizing: border-box;

		.titles {
			font-size: 16px;
			padding: 15px 0;
			border-bottom: 1px solid #eee;

			label {
				color: #FF0000;
				margin: 0 5px 0 0;
			}
		}

		.up {
			display: flex;
			flex-direction: column;
			padding: 0 100px;
			box-sizing: border-box;
			margin: 10px 0;

			.btn {
				display: flex;
				flex-direction: row;
				align-items: center;

				.upload-demos {
					width: 110px;
					height: 37px;
					background: #1890ff;
					color: #fff;
					text-align: center;
					line-height: 37px;
					position: relative;

					.input {
						position: absolute;
						left: 0;
						bottom: 0;
						width: 110px;
						height: 37px;
						opacity: 0;
					}
				}

				span {
					color: #000;
					opacity: 0.65;
					font-size: 14px;
					margin: 0 0 0 10px;
				}
			}

			.imageList {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				margin: 20px 0;

				div {
					position: relative;
					margin: 0 15px 0 0;

					img {
						width: 90px;
						height: 90px;
					}

					.icon {
						width: 22px;
						height: 22px;
						position: absolute;
						right: -11px;
						top: -11px
					}
				}

			}
		}
	}
	.image{
		padding: 15px 150px;
	}
	.ppt a:hover {
		color: #0073FF;
	}

	.ppt .dels:hover {
		color: #0073FF;
	}

	.summary {
		.up {
			display: flex;
			flex-direction: row;

			p {
				padding: 0;
			}

			textarea {
				width: 50%;
				margin: 0 0 0 10px;
				border: 1px solid #ccc;
				border-radius: 4px;
				box-sizing: border-box;
				padding: 15px;
				outline-color: #ddd;
				outline-style: 1px solid #ddd;
			}
		}
	}

	.ppt {
		padding-bottom: 20px;
		box-sizing: border-box;

		.upload-demo {
			width: 100%;

			// margin: 0 0 0 100px;
			// width: 80%;
			::v-deep .el-upload {
				width: 100% !important;
			}

			::v-deep .el-upload-dragger {
				width: 100% !important;

				img {
					margin: 10px 0 0 -40px;
				}
			}

			.el-upload-dragger .el-upload__text {
				margin: 60px 0 0 0 !important;
			}
		}

	}
	.tan{
		position: fixed;
		width: 40%;
		height: 400px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		left: 0;
		bottom: 0;
		right: 0;
		top: 0;
		margin: auto;
		background-color: #fff;
		border-radius: 15px;
		overflow: hidden;
		z-index: 100000000000;
		box-shadow: 0px 0px 6px 6px #ccc;
		div:nth-child(1){
			// background-color: #FFCC66;
			color: #FF0000;
			font-size: 18px;
			text-align: center;
			box-sizing: border-box;
			padding: 20px 0 0 0;
			height: 50px;
			width: 100%;
			text-align: left;
			img{
				margin: 0px 10px 0 20px;
				width: 25px;
				height: 25px;
			}
			span{
				margin: 20px 0px 0 0px;
			}
		}
		div:nth-child(2){
			box-sizing: border-box;
			padding: 20px;
			font-size: 15px;
			height: 370px;
			overflow: auto;
			width: 100%;
			text-align: left;
			word-wrap: break-word;
			color: #000;
			line-height: 25px;
			// white-space:normal
		}
		div:nth-child(3){
			background-color: #fff;
			font-size: 16px;
			text-align: center;
			padding: 10px 0;
			height: 30px;
			line-height: 30px;
			width: 30%;
			margin: 0 auto;
			cursor: pointer;
			span{
				background-color: #1890ff;
				color: #fff;
				display: inline-block;
				width: 150px;
				height: 30px;
				line-height: 30px;
				text-align: center;
				border-radius: 7px;
				
			}
		}
	}

	.sub {
		background-color: #fff;
		padding: 10px 20px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		border-top: 20px solid #f0f2f5;
		position: fixed;
		right: 0;
		bottom: 20px;
		// position: absolute;
		// bottom: 0;
		// width: 100%;
		box-sizing: border-box;

		button {
			width: 65px;
			height: 32px;
			background: #ffffff;
			border: 1px solid #d9d9d9;
			border-radius: 2px;
			cursor: pointer;
		}

		button:nth-child(2) {
			background: #1890ff;
			border-radius: 2px;
			color: #fff;
			margin: 0 0 0 20px;
		}
	}
</style>