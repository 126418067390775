<template>
	<div>
		<Mask></Mask>
		<div class="box" v-loading="loading">
			<img src="../assets/close2.png" @click="close" alt="">
			<div class="title">选项列表(单选)</div>
			<div class="tops">
				<!--<divclass="search">
					<span>会议中遇到的问题</span>
					<inputv-model='searchVal'type="text"placeholder="请输入关键字">
					<button@click='search'>搜索</button>
				</div>-->
				<!--<divclass="addHospital"@click='addCheck'></div>-->
				<el-button class="addHospital" type="text" @click="open" v-if='index.ind!=9999'>+新建选项</el-button>
			</div>
			<ul>
				<li @click="choose(index)" v-for="(item,index) in radioList"
					:class="{'choose':index==chooseNum,'detail':index!=detailCss}" :key="index">
					<span>{{item}}</span>
					<!--<span>{{item.answer}}</span>-->
					<!--<el-popoverplacement="top"title="标题"width="200"trigger="click"
						content="item.answer">
						<el-buttonslot="reference">{{'详情'}}</el-button>
					</el-popover>-->
					<span @click.stop="detail(index)">{{detailCss == index?'折叠':'展开'}}</span>
				</li>
			</ul>
			<button class="submit" @click="submit">确定</button>
		</div>
	</div>

</template>

<script>
	import Mask from "@/components/Mask.vue";

	//引入axios
	import axios from 'axios';
	import {
		ref
	} from 'vue'
	//引入端口配置文件
	import API_LIST from '../../list.config.js';
	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	export default {
		name: "CheckBox",
		data() {
			return {
				menuList: '',
				hospitalID: '',
				radioList: '',
				chooseNum: 9999, //选中
				meetID: '', //科室会id
				page: 1,
				type: '', //类型id
				totalNum: '',
				currentPage: 1,
				loading: false,
				detailCss: null,
				ind: '', //问题索引
				index: {
					ind: '',
					indChild: ''
				}
			}
		},
		components: {
			Mask,

		},
		mounted() {
			// this.$eventBus.off('showRadioBox')
			this.$eventBus.on('showRadioBox', data => {

				this.index = {
					ind: data.ind,
					indChild: data.indChild,
				}
				this.radioList = data.radioList
			})
		},


		methods: {
			//选中
			choose(_ind) {

				if (this.chooseNum == _ind) {
					this.chooseNum = 9999
				} else {
					this.chooseNum = _ind
				}

			},
			//关闭
			close() {
				this.chooseNum = 9999;
				this.$eventBus.emit('close', {})
			},
			//新建问题
			open() {
				this.chooseNum = 9999
				this.$prompt('请输入新选项', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(({
					value
				}) => {
					//this.$message({
					//	type:'success',
					//	message:'你的邮箱是:'+value
					//});
					if (value) {
						this.radioList.unshift(value)
					}

				}).catch(() => {
					//this.$message({
					//	type:'info',
					//	message:'取消输入'
					//});
				});
			},
			//确定
			submit() {
				if (this.chooseNum != 999) {

					this.chooseHospital()
				} else {
					ElMessage({
						message: '请选择选项',
						type: 'warning',
					})
				}
			},
			//确定选择
			chooseHospital() {
				let choose = []
				choose.push(this.radioList[this.chooseNum])

				console.log(this.$parent)
				if (this.index.ind != 9999) {
					if (this.$parent.name == 'SummaryKe') {
						this.$eventBus.emit('CheckChoose', {
							index: this.index,
							choose: choose
						})
					}else if(this.$parent.name == 'Plan'){
						this.$eventBus.emit('CheckChoose', {
							index: this.index,
							choose: choose
						})
					} else {
						this.$eventBus.emit('CheckChoose3', {
							index: this.index,
							choose: choose
						})
					}

				} else {
					this.$eventBus.emit('CheckChoose2', {
						index: this.index,
						choose: choose
					})
				}
				this.chooseNum = 999;
			},
			//查看详情
			detail(_ind) {
				if (_ind == this.detailCss) {
					this.detailCss = null
				} else {
					this.detailCss = _ind
				}

			}

		},
		beforeDestroy() {

			this.$eventBus.off('showRadioBox')
		},
	}
</script>

<style scoped lang="scss">
	.box {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 828px;
		height: 674px;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #fff;
		z-index: 200;
		border-radius: 10px;

		img {
			position: absolute;
			right: 5px;
			top: 5px;
			width: 34px;
			height: 34px;
			align-items: flex-end;
		}

		.title {
			padding: 20px 0;
			font-size: 14px;
			font-weight: 800;
		}

		.tops {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 750px;
			margin: 0 0 20px 0;

			.search {
				display: flex;
				flex-direction: row;
				align-items: center;

				span {
					font-size: 14px;
				}

				input {
					width: 272px;
					height: 32px;
					background: #ffffff;
					border: 1px solid #d9d9d9;
					border-radius: 2px;
					margin: 0 15px;
					padding: 0 0 0 5px;
				}

				button {
					width: 65px;
					height: 32px;
					background: #1890ff;
					border-radius: 2px;
					color: #fff;
				}
			}

			.addHospital {
				width: 117px;
				//height:28px;
				background: #e0f1ff;
				border-radius: 3px;
				text-align: center;
				//line-height:28px;
				font-size: 14px;
				color: #1890FF;
				cursor: pointer;
			}
		}

		ul {
			width: 90%;
			margin: 0 auto;
			height: 445px;
			overflow: auto;

			//overflow:auto;
			li {
				width: 100%;
				//height:32px;
				border-radius: 2px;
				line-height: 32px;
				margin-bottom: 10px;
				//text-align:center;
				cursor: pointer;
				padding: 0 0 0 20px;
				box-sizing: border-box;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: flex-start;


				span:nth-child(1) {
					width: 398px;
				}

				//span:nth-child(2){
				// width:298px;
				// //margin:040px00;
				//}

				span:nth-child(2) {
					//width:298px;
					margin: 2px 10px 0 0;
					border: 1px solid #fff;
					height: 25px;
					width: 35px;
					line-height: 25px;
					text-align: center;
					border-radius: 5px;

				}

			}

			.detail {
				height: 32px;
				overflow: hidden;
			}

			.choose {
				background-color: #1890ff;
				color: #fff;
			}
		}

		.submit {
			width: 65px;
			height: 32px;
			background: #1890ff;
			border-radius: 2px;
			color: #fff;
			margin: 20px 0 0 0;
			cursor: pointer;
		}
	}
</style>
